import { atom, selector, useSetRecoilState } from 'recoil';

import { RowSelectionState } from '@tanstack/react-table';
import { ColumnDef as TanstackColumnDef } from '@tanstack/react-table';

import { ProductType } from '../../types/generated';

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
}

export enum Gender {
  WOMEN = 0,
  MEN = 1,
  UNISEX = 2,
}

enum Prices {
  BuyingPrice = 'Buying Price',
  RetailPrice = 'Retail Price',
  ChooseMargin = 'Choose Margin',
  ChoosePrice = 'Choose Price',
  ChoosePriceEEE = 'Choose Price EEE',
  ShippingPrice = 'Shipping Price',
}

export type Price = {
  [key in `${Prices} ${keyof typeof Currency}`]: string;
};

export type SetupProduct = {
  // Mandatory product fields
  [propName: `Image ${number}`]: string;
  'Product Name': string;
  URL: string;

  // Optionnal product fields
  Description?: string;
  Summary?: string;
  Category?: string;
  Gender?: Gender;
  [propName: `Option ${number} Name`]: string;
  [propName: `Option ${number} Type`]: string; // @TODO : Enum
  [propName: `Option ${number} Value`]: string;
  GTIN?: string;
  SKU?: string;
  'Origin Country'?: string; // @TODO : Enum
  Stock?: number;
  [propName: `Custom Field ${number} Name`]: string;
  [propName: `Custom Field ${number} Value`]: string;
  CGU?: string;
  'FAQ Before Order'?: string;
  'FAQ After Order'?: string;
  [propName: `FAQ ${number}`]: string;
  'Deliver By Choose'?: boolean;
  'Shipping Price EUR'?: number;
  'Shipping Price USD'?: number;
  'Is Returnable'?: boolean;
  'Delivery Delays'?: number;
} & Price;

export enum CrawlingProductStatus {
  IN_PROGRESS = 'in-progress',
  ERROR = 'error',
  NOT_STARTED = 'not-started',
  SUCCESS = 'success',
}

export type CrawlingProductOption = {
  name: string;
  type: string; // @TODO : Enum
  value: string;
  guides?: string[];
};

export type CrawlingProductCustomField = {
  name: string;
  value: string;
};

export type CrawlingProductPrice = {
  amount: number;
  currency: Currency;
};

export type CrawlingProduct = {
  // Technical data
  _status: CrawlingProductStatus;
  _subRows?: CrawlingProduct[];

  // Mandatory product fields
  images: string[];
  name: string;
  url: string;

  // Optionnal product fields
  buyingPrice?: CrawlingProductPrice;
  category?: string;
  cgu?: string;
  chooseMargin?: CrawlingProductPrice;
  choosePrice?: CrawlingProductPrice;
  choosePriceEEE?: CrawlingProductPrice;
  customFields?: CrawlingProductCustomField[];
  deliverByChoose?: boolean;
  deliveryDelays?: number;
  description?: string;
  addToDescription?: string;
  faqAfterOrder?: string;
  faqBeforeOrder?: string;
  faqs?: string[];
  gender?: Gender;
  gtin?: string;
  imagesGoogleDrive?: string;
  imagesVariant?: string[];
  isReturnable?: boolean;
  isDigital?: boolean;
  options?: CrawlingProductOption[];
  originCountry?: string; // @TODO : Enum
  retailPrice?: CrawlingProductPrice;
  shippingPrice?: CrawlingProductPrice;
  sku?: string;
  stock?: number;
  summary?: string;
  vouchers?: string[];
  voucherExpirationDate?: Date;
};

export type CrawlingData = {
  products: CrawlingProduct[];
};

export type CreateProduct = {
  product_name: string;
  source_url?: string;
  buying_price_value?: number;
  buying_price_currency?: Currency;
  category?: string;
  cgu?: string;
  choose_margin_value?: number;
  choose_margin_currency?: Currency;
  choose_price_value?: number;
  choose_price_currency?: Currency;
  eee_tax_value?: number;
  eee_tax_currency?: Currency;
  deliver_by_choose?: boolean;
  delivery_delays?: number;
  description?: string;
  faq_after_order?: string;
  faq_before_order?: string;
  gender?: Gender;
  gtin?: string;
  image_google_drive?: string;
  is_returnable?: boolean;
  origin_country?: string;
  product_type?: ProductType;
  retail_price_value?: number;
  retail_price_currency?: Currency;
  shipping_price_value?: number;
  shipping_price_currency?: Currency;
  sku?: string;
  stock?: number;
  summary?: string;
  vouchers?: string;
  voucher_expires_at?: string;

  [propName: `custom_field_${number}_name`]: string;
  [propName: `custom_field_${number}_value`]: string;
  [propName: `faq_${number}`]: string;
  [propName: `product_image_${number}`]: string;
  [propName: `size_guide_${number}`]: string;
  [propName: `variant_image_${number}`]: string;
  [propName: `option_${number}_name`]: string;
  [propName: `option_${number}_value`]: string;
  [propName: `option_${number}_type`]: string;
  [propName: `option_${number}_guides`]: string;
};

export type ExtendedColumnDef<T> = TanstackColumnDef<T> & {
  accessorKey: string;
  removeColumn?: () => void;
  name?: string;
};

export const crawlingProductsState = atom<CrawlingData | undefined>({
  key: 'crawlingProductsState',
  default: undefined,
});

export const productsProcessedCountState = selector({
  key: 'productsProcessedCountState',
  get: ({ get }) => {
    const crawlingProducts = get(crawlingProductsState);

    return (
      crawlingProducts?.products?.filter(
        (entry) =>
          entry._status === CrawlingProductStatus.SUCCESS ||
          entry._status === CrawlingProductStatus.ERROR
      ) ?? []
    ).length;
  },
});

export const productsProcessedErrorCountState = selector({
  key: 'productsProcessedErrorCountState',
  get: ({ get }) => {
    const crawlingProducts = get(crawlingProductsState);

    return (
      crawlingProducts?.products?.filter(
        (entry) => entry._status === CrawlingProductStatus.ERROR
      ) ?? []
    ).length;
  },
});

export const productsProcessingState = selector({
  key: 'productsProcessingState',
  get: ({ get }) => {
    const crawlingProducts = get(crawlingProductsState);

    return (
      (
        crawlingProducts?.products?.filter(
          (entry) => entry._status === CrawlingProductStatus.IN_PROGRESS
        ) ?? []
      ).length !== 0
    );
  },
});

export const productsProcessableState = selector({
  key: 'productsProcessableState',
  get: ({ get }) => {
    const crawlingProducts = get(crawlingProductsState);

    return (
      crawlingProducts?.products?.filter(
        (entry) => entry._status !== CrawlingProductStatus.NOT_STARTED
      ) ?? []
    ).length;
  },
});

export const rowSelectionState = atom<RowSelectionState>({
  key: 'rowSelectionState',
  default: {},
});

export const selectedRowsState = atom<CrawlingProduct[]>({
  key: 'selectedRowsState',
  default: [],
});

export const contextRowSelectionState = atom<string | undefined>({
  key: 'contextRowSelectionState',
  default: undefined,
});

export const contextSelectedRowState = atom<CrawlingProduct | undefined>({
  key: 'contextSelectedRowState',
  default: undefined,
});

export const useResetSelection = () => {
  const setSelectedRows = useSetRecoilState(selectedRowsState);
  const setRowSelection = useSetRecoilState(rowSelectionState);

  const resetSelection = () => {
    // On met à jour le state de la sélection Tanstack en dehors de la logique du tableau
    setRowSelection({});
    // On doit donc aussi mettre à jour manuellement le state de la sélection de produits
    setSelectedRows([]);
  };

  return { resetSelection };
};

export const useResetContextSelection = () => {
  const setContextSelectedRow = useSetRecoilState(contextSelectedRowState);
  const setContextRowSelection = useSetRecoilState(contextRowSelectionState);

  const resetContextSelection = () => {
    setContextSelectedRow(undefined);
    setContextRowSelection(undefined);
  };

  return { resetContextSelection };
};
